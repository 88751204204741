* {
  box-sizing: border-box;
}

html {
  scroll-padding-top: 96px;
  // background:var(--mainBlue);
  text-size-adjust: none;
  -webkit-text-size-adjust: none;
  -ms-text-size-adjust: none;
  -moz-text-size-adjust: none;
}

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  min-height: 100vh;
  font-family: "InterRegular";
  scroll-behavior: smooth;
  //background:var(--mainBlue);
}

:root {
  --white: #ffffff;
  --mainBlue: #024266;
  --mainBlueDark: #002947;
  --red: #ff6f74;
  --darkRed: #ff636f;
  --primaryGradient: linear-gradient(135deg, #5ee6e5 0%, #00bad8 53.4%, #00b5d4 100%);
  --redMainColor: #ff6f74;
  --primaryColor: #00DDED;
  --mainFontColor: #B1C6D4;
  --secondaryFontColor: #004369;
}
.bg-icl{
  background-color:var(--mainBlue) !important;
}
.slick-dots {
  position: relative !important;
  padding: 10px !important;
  background-color: var(--mainBlue);
  bottom: auto !important;
  .slick-active button {
      background-color: var(--primaryColor) !important;
      & ~ span.paginator-label {
          display: block;
      }
  }
}
.slick-dots li button {
  border: none;
  padding: 3px !important;
  border-radius: 50%;
  margin: 6px;
  background-color: var(--mainBlueDark) !important;
  width: 0 !important;
  height: 0 !important;
  &::before {
      content: none !important;
  }
  & ~ span.paginator-label {
      display: none;
      font-size: 11px;
      color: var(--white);
      position: absolute;
      top: 14px;
      left: 50%;
      transform: translateX(-50%);
  }
}
.slick-dotted.slick-slider {
  margin-bottom: 0 !important;
}
/* sweet-alert css */
.swal2-show {
  background: var(--mainBlue);
  border: 1px solid var(--primaryColor);
  color: white;
}
.swal2-popup.swal2-modal.swal2-icon-error.swal2-show {
  background: var(--mainBlue);
  border: 1px solid var(--primaryColor);
  color: white;
}
div:where(.swal2-icon).swal2-error {
  border: 0.15em solid rgba(0,0,0,0);
  border-color: var(--primaryColor) !important;
}
div:where(.swal2-icon).swal2-error [class^=swal2-x-mark-line] {
  background-color: var(--primaryColor) !important;
  height: 0.15rem !important;
}
div:where(.swal2-container) button:where(.swal2-styled).swal2-confirm {
  background-color:var(--mainBlue) !important;
  border:  1px solid var(--primaryColor) !important;
  outline: none !important;
  box-shadow: none !important;
}
div:where(.swal2-container) button:where(.swal2-styled).swal2-cancel {
  background-color: var(--primaryColor)
}
.colored-toast.swal2-icon-error {
  background-color: var(--mainBlue) !important;
}
.colored-toast.swal2-icon-success {
  background-color: var(--mainBlue) !important;
}